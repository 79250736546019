import constant from "../../constant";
import axios from "axios";
import router from "../../router";
import { printData } from "../../utils/printUtils";
const api = constant.api + "academies";
// initial state
const state = () => ({
  all: [],
  academies: [],
  academy: {},
  
});

// getters
const getters = {
  getAll: (state) => {
    return state.all;
  },
  getAcademy: (state) => {
    return state.academy;
  },

  getAcademies: (state) => {
    return state.academies;
  },

};

// actions
const actions = {
 
  async getAll({ commit }) {
    await axios.get(api).then((response) => {
      let academies = response.data;
      commit("setAcademies", academies);
    });
  },

  async createFichePresence({ commit }) {
    await axios.get(api+"_presence_create").then((response) => {
      let academies = response.data;
      
    });
  },

  async show({ commit }, reference) {
    await axios.get(api + "/" + reference).then((response) => {
      let academy = response.data;
      commit("setAcademy", academy);
    });
  },

  async destroy({ commit,dispatch }, reference) {
    await axios.delete(api + "/" + reference).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      let academies = response.data.data;
      dispatch("notification/store", { status, message }, { root: true });

      if (status == "success") {
        commit("setAcademies", academies);
        router.push({
          name: "academies-index",
        });
      }
    });
  },

  async store({ dispatch }, data) {
    await axios.post(api, data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      dispatch("notification/store", { status, message }, { root: true });

      if (status == "success") {
        router.push({
          name: "academies-index",
        });
      }
    });
  },

  async printFichePresence({ dispatch }, ) {
    try {
      dispatch(
        "notification/storeDownloading",
        { 
          status: "success",
          message:
            "La fiche de presence est en téléchargement, Veuillez patienter...",
        },
        { root: true }
      );

      //  const apiBaseUrl = this.getters["api/getApiBaseUrl"];
      await printData([], `${api}_print_fiche_presence`, "GET");
    } catch (error) {
      console.error(error);
    }
  },

  async printRegistration({ dispatch }, reference) {
    try {
      dispatch(
        "notification/storeDownloading",
        { 
          status: "success",
          message:
            "La fiche de renseignement est en téléchargement, Veuillez patienter...",
        },
        { root: true }
      );

      //  const apiBaseUrl = this.getters["api/getApiBaseUrl"];
      await printData([], `${api}_print_registration`, "GET", reference);
    } catch (error) {
      console.error(error);
    }
  },
  
};

// mutations
const mutations = {
  setAcademies(state, academies) {
    state.all = academies;
    state.academies = academies;
  },
  setAcademy(state, academy) {
    state.academy = academy;
  },

  
 
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
