<template>
  <div class="">
    <div class="row justify-content-end">
      <div class="col-auto">
        <button @click="refresh()" class="btn btn-primary">
          <i class="fa fa-plus"></i> Refresh
        </button>
      </div>

      <div class="col-auto">
        <button @click="$router.push({
          name:'issues-create'
        })" class="btn btn-primary">
          <i class="fa fa-plus"></i> Add issue
        </button>
      </div>
    </div>
    <br>
    <table class="table table-striped table-hover">
      <thead>
        <tr>
            
            <th></th>
            <th> Terrain </th>
            
            <th>Statut</th>
            <th></th>
          </tr>
      </thead>
      <tbody>
          <tr v-for="issue in issues" :key="issue.id">
            <th></th>
            <th>
                {{ issue.content }}
            </th>

            <th>
              <!-- {{ issue.is_active	 }}  -->
              <button v-if="issue.status=='Open'"
                @click="toggleIssueStatus(issue.reference,false,issue.terrain_reference)" class="btn btn-success">
                Open
              </button>
              <button v-else @click="toggleIssueStatus(issue.reference,true,issue.terrain_reference)" class="btn btn-danger">
                Closed
              </button>
            </th>
            <th>
              {{ issue.remark }} 
            </th>
            <th>
              <button @click="$router.push(
                {
                  name: 'issues-edit',
                  params: {
                    reference:issue.reference
                }
                })" class="btn ">

                Editer 
              </button>
              <button @click="remove(issue.reference)" class="btn ">

                Annuler
              </button>
            </th>
            
          </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
    };
  },
  async mounted() {
    await this.$store.dispatch("issue/getAll");
    
  },
  computed: {
    ...mapGetters('issue', {
      issues: 'getIssues',
    }),
  },
  methods: {
    
    async refresh() {
      await this.$store.dispatch("issue/getAll");
    },

    async remove(reference) {
      await this.$store.dispatch("issue/destroy",reference);
    },

    async toggleIssueStatus(reference,status,terrain_reference) {
      await this.$store.dispatch("issue/toggleIssueStatus", {
        reference,
        status,
        terrain_reference
      });
    },
  }
};
</script>

<style scoped>
.bg-success {
  background: #78ff5d !important;
}
</style>
