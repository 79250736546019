<template>
  <div>
    <!-- Search and Action Buttons -->
    <div class="row justify-content-end align-items-end mb-3">
      
      <div class="col-auto">
        <button @click="refresh" class="btn btn-secondary">
          <i class="bi bi-arrow-repeat"></i>
          Actualiser
        </button>
      </div>

      <div class="col-auto">
        <button @click="print" class="btn btn-secondary">
          <i class="bi bi-printer"></i>
          Imprimer
        </button>
      </div>

      <div class="col-auto">
        <button @click="createPresence()" class="btn btn-secondary">
          <i class="bi bi-printer"></i>
          Presence
        </button>
      </div>
    </div>
 
    <!-- DataTable -->
    <vue-data-table 
      :headers="headers" 
      :data="academies" 
      :buttons="tableButtons" 
      :summableColumns="['totalPayments','amountToPay','nextPaymentAmount']" 
    />
  </div>
</template>


<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      headers: [
        { label: "Référence", field: "reference" },
        { label: "Nom Complet", field: "fieldFullName" },
        { label: "Naissance", field: "fieldBirthDate" },
        { label: "Adresse", field: "fieldAddress" },
        { label: "Téléphone", field: "fieldPhone" },
        { label: "Fixe", field: "fieldHomePhone" },
        { label: "M à Payer", field: "amountToPay" },
        { label: "M.Payé", field: "totalPayments" },
        { label: "Prochaine Paiement", field: "nextPaymentDate" },
        { label: "M. Prochaine ", field: "nextPaymentAmount" },
       
      ],
      tableButtons: [
       
        {
          label: "",
          class: "text-success",
          icon: "bi bi-eye-fill",
          handler: (row) => this.$router.push({ name: 'customers-details', params: { reference: row.reference } })
        },
        {
          label: "",
          class: "text-secondary",
          icon: "bi bi-pencil-square",
          handler: (row) => this.$router.push({ name: 'customers-edit', params: { reference: row.reference } })
        },
        {
          label: "",
          class: "text-danger",
          icon: "bi bi-trash",
          handler: (row) => this.remove(row)
        },

        {
          label: "",
          class: "text-danger",
          icon: "bi bi-printer",
          handler: (row) => this.printRegistration(row.reference)
        }
      ]
    };
  },
  computed: {
    ...mapGetters("academy", {
      academies: "getAcademies",
    }),
  },
  mounted() {
    this.refresh();
  },
  methods: {
    
    async refresh() {
      await this.$store.dispatch("academy/getAll");
    },
 
    async print() {
      await this.$store.dispatch("academy/printFichePresence");
    },

    async createPresence() {
      await this.$store.dispatch("academy/createFichePresence");
    },

    async printRegistration(reference) {
      await this.$store.dispatch("customer/printRegistration",reference);
    },

    async remove(data) {
      let academy = data;
      this.$store.dispatch("academy/destroy", academy.reference);
      // await this.$confirm({
      //   message: "Vous voulez supprimer le client " + academy.fullName + " ?",
      //   button: { no: "Non", yes: "Oui" },
      //   callback: (confirm) => {
      //     if (confirm) {
      //     }
      //   },
      // });
    }
  }
};
</script>
