import constant from "../../constant";
import axios from "axios";
import router from "../../router";
const api = constant.api + "issues";
// initial state
const state = () => ({
  all: [],
  issues: [],
  issue: {},
}); 

// getters
const getters = {
  getAll: (state) => {
    return state.all;
  },
  getIssue: (state) => {
    return state.issue;
  },

  getIssues: (state) => {
    return state.issues;
  },
};

// actions 
const actions = {
  async getAll({ commit }) {
    await axios.get(api).then((response) => {
      let issues = response.data;
      commit("SET_ISSUES", issues);
    });
  },
  async show({ commit }, reference) {
    await axios.get(api + "/" + reference).then((response) => {
      let issue = response.data;
      commit("SET_ISSUE", issue);
    });
  },

  async store({ commit, dispatch }, data) {
    await axios.post(api, data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      dispatch("notification/store", { status, message }, { root: true });
      if (status == "success") router.push({ name: "issues-index" });
    });
  },

  async update({ dispatch }, data) {
    await axios.put(api + "/" + data.reference, data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      dispatch("notification/store", { status, message }, { root: true });

      if (status == "success") {
        router.push({
          name: "issues-index",
        });
      }
    });
  },

  async toggleIssueStatus({ commit,dispatch }, data) {
    await axios
      .put(api + "_status/" + data.reference, data)
      .then((response) => {
        let message = response.data.message;
        let status = response.data.status;
        dispatch("notification/store", { status, message }, { root: true });

        if (status == "success") {
          let issues = response.data.data;
          commit("SET_ISSUES", issues);
        }
      });
  },

  async destroy({ commit, dispatch }, reference) {
    await axios.delete(api + "/" + reference).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      let issues = response.data.data;
      dispatch("notification/store", { status, message }, { root: true });
      if (status == "success") commit("SET_ISSUES", issues);
    });
  },
};

// mutations
const mutations = {
  SET_ISSUES(state, issues) {
    state.all = issues;
    state.issues = issues;
  },
  SET_ISSUE(state, issue) {
    state.issue = issue;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
