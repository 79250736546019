var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('div',{staticClass:"row justify-content-end"},[_c('div',{staticClass:"col-auto"},[_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){return _vm.refresh()}}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v(" Refresh ")])]),_c('div',{staticClass:"col-auto"},[_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){return _vm.$router.push({
        name:'issues-create'
      })}}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v(" Add issue ")])])]),_c('br'),_c('table',{staticClass:"table table-striped table-hover"},[_vm._m(0),_c('tbody',_vm._l((_vm.issues),function(issue){return _c('tr',{key:issue.id},[_c('th'),_c('th',[_vm._v(" "+_vm._s(issue.content)+" ")]),_c('th',[(issue.status=='Open')?_c('button',{staticClass:"btn btn-success",on:{"click":function($event){return _vm.toggleIssueStatus(issue.reference,false,issue.terrain_reference)}}},[_vm._v(" Open ")]):_c('button',{staticClass:"btn btn-danger",on:{"click":function($event){return _vm.toggleIssueStatus(issue.reference,true,issue.terrain_reference)}}},[_vm._v(" Closed ")])]),_c('th',[_vm._v(" "+_vm._s(issue.remark)+" ")]),_c('th',[_c('button',{staticClass:"btn",on:{"click":function($event){return _vm.$router.push(
              {
                name: 'issues-edit',
                params: {
                  reference:issue.reference
              }
              })}}},[_vm._v(" Editer ")]),_c('button',{staticClass:"btn",on:{"click":function($event){return _vm.remove(issue.reference)}}},[_vm._v(" Annuler ")])])])}),0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th'),_c('th',[_vm._v(" Terrain ")]),_c('th',[_vm._v("Statut")]),_c('th')])])
}]

export { render, staticRenderFns }