<template>
  <div>
    <br>
    <div class="row justify-content-end">
      <!-- Modify button with pencil icon -->
      <div v-if="reservation.reference" class="col-auto">
        <button @click="$router.push({
          name: 'reservations-edit',
          params: { reference: reservation.reference }
        })" class="btn btn-warning">
          <i class="bi bi-pencil"></i> Modifier
        </button>
      </div>

      <!-- Print button with print icon -->
      <div v-if="reservation.reference" class="col-auto">
        <button @click="print()" class="btn btn-warning">
          <i class="bi bi-printer"></i> Imprimer
        </button>
      </div>

      <!-- Add Payment button with plus-circle icon -->
      <div v-if="!reservation.paid" class="col-auto">
        <button @click="$router.push({
          name: 'payments-create',
          params: {
            reference: reservation.field_reference
          },
          query: {
            reservation: reservation.reference
          }
        })" class="btn btn-secondary">
          <i class="bi bi-plus-circle"></i> Ajouter Paiements
        </button>
      </div>

      <!-- Create Match button with football icon -->
      <div v-if="reservation.reference" class="col-auto">
        <button @click="$router.push({
          name: 'matches-create',
          params: { reference: reservation.reference }
        })" class="btn btn-warning">
          <i class="bi bi-futbol"></i> Créer un match
        </button>
      </div>

      <!-- Send WhatsApp button with WhatsApp icon -->
      <div v-if="reservation.reference" class="col-auto">
        <button @click="sendWhatsAppMessage()" class="btn btn-success">
          <i class="bi bi-whatsapp"></i> Envoyer via WhatsApp
        </button>
      </div>

      <!-- Cancel button with X icon -->
      <div v-if="reservation.reference" class="col-auto">
        <button @click="remove()" class="btn btn-danger">
          <i class="bi bi-x-circle"></i> Annuler
        </button>
      </div>
    </div>

    <br>

    <div class="row align-items-stratch">

      <div class="col-xl-3 col-6 my-3">
        <label for="">Référence :</label>
        <div>
          {{ reservation.reference }}
        </div>
      </div>

      <div class="col-xl-3 col-6 my-3">
        <label for=""> Date </label>
        <div>
          {{ reservation.date }}
        </div>
      </div>

      <div class="col-xl-3 col-6 my-3">
        <label for=""> Heure </label>
        <div>
          {{ reservation.startTime }} - {{ reservation.endTime }}
        </div>
      </div>

      <div class="col-xl-3 col-6 my-3">
        <label for=""> Prix</label>
        <div>
          {{ reservation.price }}
        </div>
      </div>



      <div class="col-xl-3 col-6 my-3">
        <label for="">Nom :</label>
        <div>
          {{ reservation.fullName }}
        </div>
      </div>

      <div class="col-xl-3 col-6 my-3">
        <label for="">Téléphone :</label>
        <div>
           {{ formatPhoneNumber(reservation.phone) }}
        </div>
      </div>



      <div class="col-xl-3 col-6 my-3">
        <label for="">Méthode de paiement :</label>
        <div>
          {{ reservation.paymentMethod || 'Non spécifiée' }}
        </div>
      </div>

      <div class="col-xl-3 col-6 my-3">
        <label for="">Date de paiement :</label>
        <div>
          {{ reservation.dateOfPayment || 'Non payé' }}
        </div>
      </div>

      <div class="col-xl-3 col-6 my-3">
        <label for=""> Terrain :</label>
        <div>
          {{ reservation.terrainFullName || 'Matche Non Crée' }}
        </div>
      </div>

      <div class="col-xl-3 col-6 my-3">
        <label for=""> Reste Pour le Matche :</label>
        <div>
          {{ reservation.matcheTimeRest || 'Matche Non Crée' }}
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters("reservation", {
      reservation: "getReservation",
    }),
  },
  methods: {
    async print() {
      await this.$store.dispatch("reservation/print", this.$route.params.reference);
    },
    async remove() {
      await this.$store.dispatch("reservation/destroy", this.$route.params.reference);
    },

    sendWhatsAppMessage() {
      let phone = ''
      let fullName = ''
      if (this.reservation.field) {
        phone = this.reservation.field.phone;
        fullName = this.reservation.field.fullName;
      }

      // Remove all spaces from the phone number
      phone = phone.replace(/\s+/g, '');

      // Extract the last 9 digits
      const lastSevenDigits = phone.slice(-9);

      // Add '212' at the beginning
      const formattedPhone = `212${lastSevenDigits}`;

     
      // French message
      const frenchMessage = `
      Bonjour ${fullName},
      Votre réservation est confirmée pour le ${this.reservation.date} à ${this.reservation.startTime}.
      Prix: ${this.reservation.price}€.
      Référence: ${this.reservation.reference}.
    `;

      // Arabic message
      const arabicMessage = `
      مرحباً ${fullName}،
      تم تأكيد حجزك في ${this.reservation.date} الساعة ${this.reservation.startTime}.
      السعر: ${this.reservation.price}€.
      المرجع: ${this.reservation.reference}.
      
    `;

      // Combine messages
      const message = `${frenchMessage}\n\n${arabicMessage}`;

      // Encode the message
      const encodedMessage = encodeURIComponent(message.trim());

      // Open WhatsApp link
      window.open(`https://wa.me/${formattedPhone}?text=${encodedMessage}`, "_blank");
    },
    formatPhoneNumber(phone) {
      if (!phone) return ""; // Handle empty or undefined phone numbers
      return phone.replace(/(\d{2})(?=(\d{2})+(?!\d))/g, '$1 . ');
    },
  },
  async beforeMount() {
    await this.$store.dispatch("reservation/show", this.$route.params.reference);
  },
};
</script>
